.app {
  background-color: #369;
  height: 100vh;
  text-align: center;
}

.radio-list {
  background-color: #369;
  padding: 10px;
  white-space: nowrap;
  overflow-x: auto;

  .active {
    transform: scale3d(1.1, 1.1, 1)
  }

  & > div {
    display: inline-block;
    margin: 0 5px;

    & > img {
      height: 75px;
      width: 75px;
      box-shadow: 0 0 5px rgba(0, 0, 0, .5);
      border-radius: 5%;
    }
  }
}

.forecast {
  background-color: #369;
  white-space: nowrap;
  overflow-x: auto;
  padding: 10px;
  height: 100px;

  & > div {
    display: inline-block;
    height: 100px;
    width: 60px;

    & > img {
      width: 60px;
      height: 60px;
    }

    .temp {
      color: #fff;
      font-size: 1em;
      font-weight: bold;
    }

    .date {
      color: #fffa47;
      font-size: 0.76em;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;

  & + .column {
    border-left: 1px solid #5D83B2;
  }
}

.current {
  background-color: #369;
  padding: 20px 0 0;

  .time {
    font-size: 60px;
    color: #FDFDC1;
  }

  .full-date {
    font-size: 20px;
    color: #fff;
  }

  .weekday {
    font-size: 18px;
    color: #d7d7d7;
  }

  .temp {
    color: #fff;
    font-size: 40px;
    font-weight: bold;
  }

  .humidity {
    color: #B1B1B1;
    font-size: 0.9em;
    font-weight: bold;
    display: inline-block;
    line-height: 22px;
    margin-top: 8px;

    &:before {
      content: " ";
      display: inline-block;
      height: 22px;
      width: 22px;
      margin-right: 5px;
      vertical-align: middle;
      background: url("./img/drop.svg") center center / contain no-repeat;
    }
  }

  .voltage {
    color: #B1B1B1;
    display: inline-block;
    font-size: 0.9em;
    line-height: 22px;
    margin-top: 8px;

    &:before {
      content: " ";
      display: inline-block;
      height: 22px;
      width: 22px;
      margin-right: 5px;
      vertical-align: middle;
      background: url("./img/flash.svg") center center / contain no-repeat;
    }

    &.low {
      color: #ff5c70;
    }
    font-weight: bold;
  }

  .dat {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 0.8em;
    color: rgb(255, 250, 71);
  }


  .house {
    background: url('./img/home.svg') no-repeat center center;
    background-size: contain;
  }
}